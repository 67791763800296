@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

laser-editor {
  --margin-top: 0;
  --margin-right: 0;
  --margin-bottom: 0;
  --margin-left: 0;
  --editor-bgcolor: #EDF2F7;
  --toolbar-bgcolor: #CBD5E0;
  --font-family: 'Open Sans', sans-serif;
}